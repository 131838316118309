import {
  ISupplierFindSupplierCollectionListItem,
  ISupplierInsertReq,
} from '@/modules/setting-manage/supplier-manage/api/type';

/**
 * 弹窗操作类型
 */
export enum SUP_DIALOG_OPERATE_ENUM {
  /* 新增 */
  ADD = 'ADD',
  /* 编辑 */
  MODIFY = 'MODIFY'
}
export const SUP_DIALOG_OPERATE_LIST = [
  { value: SUP_DIALOG_OPERATE_ENUM.ADD, label: '新建供应商' },
  { value: SUP_DIALOG_OPERATE_ENUM.MODIFY, label: '编辑供应商' },
];

export interface ISupplierOperateConfig {
  visible: boolean;
  /*
  * 操作类型
  * */
  operateType: SUP_DIALOG_OPERATE_ENUM;
  /*
  * 组织数据
  * */
  data?: ISupplierFindSupplierCollectionListItem | null;
}

export interface IOrgUserOperateForm extends ISupplierInsertReq {}
