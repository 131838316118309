import { SUP_DIALOG_OPERATE_ENUM, ISupplierOperateConfig } from './type';
import { reactive } from 'vue';
import { ISupplierFindSupplierCollectionListItem } from '@/modules/setting-manage/supplier-manage/api/type';

export const useSupplierEditDialog = () => {
  const supplierDialogConfig = reactive<ISupplierOperateConfig>({
    visible: false,
    operateType: SUP_DIALOG_OPERATE_ENUM.ADD,
    data: null,
  });

  const handleAddOrgUser = () => {
    supplierDialogConfig.visible = true;
    supplierDialogConfig.operateType = SUP_DIALOG_OPERATE_ENUM.ADD;
  };
  const handleEditOrgUser = (row: ISupplierFindSupplierCollectionListItem) => {
    supplierDialogConfig.data = row;
    supplierDialogConfig.operateType = SUP_DIALOG_OPERATE_ENUM.MODIFY;
    supplierDialogConfig.visible = true;
  };
  return {
    supplierDialogConfig,
    handleAddOrgUser,
    handleEditOrgUser,
  };
};
