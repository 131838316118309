const usePermissionConfig = () => {
  return {
    /*
    * 新建供应商
    * 控制：系统设置-系统设置-供应商管理-新建供应商
    * 跳转路由名称：
    * */
    ADD_GYS: 'MOKEN-XTSZ-XTSZ-GYSGL-XJGYS',
    /*
    * 编辑供应商
    * 控制：系统设置-系统设置-供应商管理-编辑供应商
    * 跳转路由名称：
    * */
    UPDATE_GYS: 'MOKEN-XTSZ-XTSZ-GYSGL-BJGYS',
    /*
    * 供应商禁用启用
    * 控制：系统设置-系统设置-供应商管理-禁用启用
    * 跳转路由名称：
    * */
    UPDATE_GYS_STATE: 'MOKEN-XTSZ-XTSZ-GYSGL-JYQY',
  };
};

export default usePermissionConfig;
