import { IColumnsItem } from '@/components/custom-table/package/type.d';

// import { $filters } from '@/core/plugins/filter';
import { ISupplierFindSupplierCollectionListItem } from '@/modules/setting-manage/supplier-manage/api/type';
import {
  ENABLE_STATUS_ENUM,
} from '@/modules/setting-manage/supplier-manage/constant';
import dayjs from 'dayjs';
import { YES_OR_NO_ENUM } from '@/constant/global';

export const renderColumns = (
  handleEdit: (row: ISupplierFindSupplierCollectionListItem) => void,
  handleEnableChange: (row: ISupplierFindSupplierCollectionListItem) => void,
  canUpdateUser: boolean,
  canUpdateState: boolean,
): IColumnsItem<ISupplierFindSupplierCollectionListItem>[] => {
  return [
    {
      label: '序号',
      type: 'index',
      width: '55',
      align: 'center',
    },
    {
      label: '供应商名称',
      minWidth: '120',
      prop: 'supplierName',
      align: 'center',
    },
    {
      label: '创建时间',
      minWidth: '120',
      prop: 'createdTime',
      align: 'center',
      render: (row:ISupplierFindSupplierCollectionListItem) => {
        return (
          <>
            {row.createdTime ? dayjs(row.createdTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        );
      },
    },
    {
      label: '创建人',
      minWidth: '120',
      prop: 'creatorName',
      align: 'center',
    },
    {
      label: '最新修改时间',
      minWidth: '120',
      prop: 'revisedTime',
      align: 'center',
      render: (row:ISupplierFindSupplierCollectionListItem) => {
        return (
          <>
            {row.revisedTime ? dayjs(row.revisedTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        );
      },
    },
    {
      label: '联系人',
      minWidth: '120',
      prop: 'contacts',
      align: 'center',
    },
    {
      label: '联系电话',
      minWidth: '120',
      prop: 'mobile',
      align: 'center',
    },
    {
      label: '启用状态',
      width: '120',
      align: 'center',
      render: (row: ISupplierFindSupplierCollectionListItem) => {
        return (
        <div>
          {canUpdateState && (
            <el-switch v-model={row.isEnable}
                       disabled={row.isEditable === YES_OR_NO_ENUM.NO}
                       inlinePrompt={false}
                       active-value={ENABLE_STATUS_ENUM.ENABLE}
                       inactive-value={ENABLE_STATUS_ENUM.DISABLE}
                       before-change={() => handleEnableChange(row)}
                       >
            </el-switch>
          )}
        </div>
        );
      },
    },
    {
      width: '150px',
      label: '操作',
      align: 'center',
      fixed: 'right',
      render: (row: ISupplierFindSupplierCollectionListItem) => {
        return (
          <div>
            {canUpdateUser && (
              <el-button type="text" onClick={() => handleEdit(row)}
                         disabled={row.isEditable === YES_OR_NO_ENUM.NO}
              >
                编辑
              </el-button>
            )}
          </div>
        );
      },
    },
  ];
};
