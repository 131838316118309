import { ref, computed, watch, Ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import {
  // IGoodsItem,
  ICustomParams,
  // IParams,
  // ISortParams,
  ISearchKeys,
  IFilterTagsItem,
  // IParams,
} from '../type.d';
import {
  MK_SORT_WORD_LIST,
} from '@/modules/miaokuan-manage/common/constant';
import { $filters } from '@/core/plugins/filter';
import {
  ENABLE_STATUS_LIST,
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/setting-manage/supplier-manage/constant';
import { useList as useCommonList } from '@/hooks/use-list';
import {
  ISupplierFindAllCreatorRes,
  ISupplierFindSupplierCollectionListItem,
  ISupplierFindSupplierCollectionReq,
} from '@/modules/setting-manage/supplier-manage/api/type';
import { getSupplierList } from '@/modules/setting-manage/supplier-manage/api';
import dayjs from 'dayjs';

export default (
  CREATOR_OPTIONS:Ref<ISupplierFindAllCreatorRes>,
  keywords:Ref<string>,
) => {
  const baseParams: ICustomParams = {
    supplierName: '',
    isEnable: '',
    creatorId: '',
    createdTime: [],
    pageNum: 1,
    pageSize: 10,
  };

  const {
    params,
    tableTotal,
    tableData,
    tableLoading,
    handleSearch,
    handleReset,
    handleSizeChange,
    handleCurrentChange,
  } = useCommonList<ISupplierFindSupplierCollectionListItem, ISupplierFindSupplierCollectionReq>({
    request: {
      api: getSupplierList,
      params: baseParams,
      // pageNumKey: 'pageNum',
      handleParams: (paramsObj) => {
        const filterParams:ISupplierFindSupplierCollectionReq = cloneDeep(paramsObj);
        filterParams.supplierName = keywords.value;
        if (filterParams.createdTime?.length === 2) {
          [filterParams.startDate = '', filterParams.endDate = ''] = filterParams.createdTime;
        }
        delete filterParams.createdTime;
        return filterParams;
      },
    },
    // response: {
    //   tableDataKey: 'data.list',
    //   totalKey: 'data.total',
    // },
  });

  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.IS_ENABLE:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '启用状态',
            key,
            content: $filters.getEnumLabel(ENABLE_STATUS_LIST, value),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.CREATOR_ID:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '创建人',
            key,
            content: (CREATOR_OPTIONS.value?.find(item => item.creatorId === value) ?? { creatorName: '' }).creatorName,
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.CREATED_TIME:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY-MM-DD'),
              dayjs(value[1]).format('YYYY-MM-DD'),
            ];
            tags.push({
              label: '创建时间',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.IS_ENABLE:
      case SEARCH_BASE_SETTING_ENUM.CREATOR_ID:
        params.value[key] = '';
        break;
      case SEARCH_BASE_SETTING_ENUM.CREATED_TIME:
        params.value[key] = [];
        break;
      default:
        break;
    }
  };
  // const handleReset = () => {
  //   params.value = cloneDeep(baseParams);
  // };
  watch(() => filtersTags.value, () => {
    // handleSearch(params.value.pageNum);
  });
  watch(() => [params.value.isEnable, params.value.creatorId, params.value.createdTime], () => {
    handleSearch(params.value.pageNum);
  });
  return {
    filtersTags,
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    baseParams,
    sortList: MK_SORT_WORD_LIST,
    params,
    tableTotal,
    tableData,
    tableLoading,
    handleSearch,
    handleSizeChange,
    handleCurrentChange,

  };
};
