import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import * as Types from './type';

const server = DOMAIN_SYSTEM_ENUM.tiangong;
/**
 * 获取创建人选项
 */
export function getCreatorOpts() {
  return http.get<Types.ISupplierFindAllCreatorRes>({
    url: '/moken-portal/web/v1/supplier/find-all-creator',
    server,
    // loading: true,
  });
}
/**
 * 获取供应商列表
 */
export function getSupplierList(data:Types.ISupplierFindSupplierCollectionReq) {
  return http.post<Types.ISupplierFindSupplierCollectionRes>({
    url: '/moken-portal/web/v1/supplier/find-supplier-collection',
    server,
    loading: true,
    data,
  });
}

/**
 * 新增供应商
 */
export function postSupplierAdd(data:Types.ISupplierInsertReq) {
  return http.post<Types.ISupplierInsertRes>({
    url: '/moken-portal/web/v1/supplier/insert',
    server,
    loading: true,
    data,
  });
}

/**
 * 编辑供应商
 */
export function postSupplierUpdate(data:Types.ISupplierUpdateReq) {
  return http.post<Types.ISupplierUpdateRes>({
    url: '/moken-portal/web/v1/supplier/update',
    server,
    loading: true,
    data,
  });
}

/**
 * 启用/停用供应商
 */
export function postSupplierEnable(data:Types.ISupplierEnableReq) {
  return http.post<Types.ISupplierEnableRes>({
    url: '/moken-portal/web/v1/supplier/enable',
    server,
    loading: true,
    data,
  });
}
