import { SYSTEM_ENUM } from '@/constant/global';
// 组织用户状态
export enum ORG_USER_STATUS_ENUM {
  /*
  * 禁用
  * */
  DISABLE = '0',
  /*
  * 启用
  * */
  ENABLE = '1',
}
export const ORG_USER_STATUS_LIST = [
  { value: ORG_USER_STATUS_ENUM.ENABLE, label: '启用' },
  { value: ORG_USER_STATUS_ENUM.DISABLE, label: '停用' },
];

export const ORG_USER_STATUS_REVERT_LIST = [
  { value: ORG_USER_STATUS_ENUM.ENABLE, label: '停用' },
  { value: ORG_USER_STATUS_ENUM.DISABLE, label: '启用' },
];

// 状态转换，用于启停用切换
export const STATUS_REVERT_MAP = {
  [ORG_USER_STATUS_ENUM.DISABLE]: ORG_USER_STATUS_ENUM.ENABLE,
  [ORG_USER_STATUS_ENUM.ENABLE]: ORG_USER_STATUS_ENUM.DISABLE,
};

export type SYSTEM_NAME_ALIAS_MAP_TYPE = Record<SYSTEM_ENUM, string>;

export const SYSTEM_NAME_ALIAS_MAP: SYSTEM_NAME_ALIAS_MAP_TYPE = {
  [SYSTEM_ENUM.MOKEN]: '角色',
};

/* 分割线 */
export enum SEARCH_BASE_SETTING_ENUM{
  /* 供应商名称 */
  SUPPLIER_NAME='supplierName',
  /* 启用状态 */
  IS_ENABLE='isEnable',
  /* 创建人 */
  CREATOR_ID='creatorId',
  /* 创建时间 */
  CREATED_TIME='createdTime',
}

// 组织用户状态
export enum ENABLE_STATUS_ENUM {
  /*
  * 禁用
  * */
  DISABLE = '0',
  /*
  * 启用
  * */
  ENABLE = '1',
}
export const ENABLE_STATUS_LIST = [
  { value: ENABLE_STATUS_ENUM.ENABLE, label: '启用' },
  { value: ENABLE_STATUS_ENUM.DISABLE, label: '停用' },
];

export const MAP_ENABLE_STATUS_LIST = [
  { value: ENABLE_STATUS_ENUM.ENABLE, label: '停用' },
  { value: ENABLE_STATUS_ENUM.DISABLE, label: '启用' },
];
