
import { defineComponent, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import useList from './composables/use-list';
import { renderColumns } from './components/table-list/tableData';
import SupplierEditDialog from './components/supplier-edit-dialog/index.vue';
import { useSupplierEditDialog } from './components/supplier-edit-dialog/use-supplier-edit-dialog';
import { $filters, has } from '@/core/plugins/filter';
import usePermissionConfig from '../use-permission-config';
import { useSearchConfig } from './composables/use-search-config';
import {
  ISupplierFindAllCreatorRes,
  ISupplierFindSupplierCollectionListItem,
} from '@/modules/setting-manage/supplier-manage/api/type';
import { ENABLE_STATUS_ENUM, MAP_ENABLE_STATUS_LIST } from '@/modules/setting-manage/supplier-manage/constant';
import { getCreatorOpts, postSupplierEnable } from '@/modules/setting-manage/supplier-manage/api';

export default defineComponent({
  components: {
    SupplierEditDialog,
  },
  setup() {
    const permissionConfig = usePermissionConfig();
    const CREATOR_OPTIONS = ref<ISupplierFindAllCreatorRes>([]);
    const keywords = ref<string>('');
    const {
      // filtersTags,
      // baseParams,
      sortFiltersTags,
      handleRemoveFilterItem,
      // handleReset,
      // handleCurrentSizeChange,
      params,
      tableTotal,
      tableData,
      tableLoading,
      handleSearch,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
    } = useList(CREATOR_OPTIONS, keywords);

    // 字典和搜索项el
    const {
      searchConfig,
    } = useSearchConfig(params, CREATOR_OPTIONS);

    const getDictOpts = async () => {
      const { data = [] } = await getCreatorOpts();
      CREATOR_OPTIONS.value = data;
    };

    // 新建｜编辑供应商
    const {
      supplierDialogConfig,
      handleAddOrgUser,
      handleEditOrgUser,
    } = useSupplierEditDialog();

    const handleAddSuccess = () => {
      handleSearch(params.value.pageNum);
      getDictOpts();
    };

    // 启用状态切换
    const handleEnableChange = async (row: ISupplierFindSupplierCollectionListItem) => {
      try {
        const label = $filters.getEnumLabel(MAP_ENABLE_STATUS_LIST, row.isEnable);
        await ElMessageBox.confirm(
          `确认${label}【${row.supplierName}】供应商`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        );
        await postSupplierEnable({
          id: row.id,
          isEnable: row.isEnable === ENABLE_STATUS_ENUM.ENABLE ? ENABLE_STATUS_ENUM.DISABLE : ENABLE_STATUS_ENUM.ENABLE,
        });
        ElMessage.success(`供应商：【${row.supplierName}】${label}成功`);
        handleSearch(params.value.pageNum);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    };

    // 按钮权限控制
    const canUpdateUser = computed(() => has(permissionConfig.UPDATE_GYS));
    const canUpdateState = computed(() => has(permissionConfig.UPDATE_GYS_STATE));

    const init = async () => {
      handleSearch();
      getDictOpts();
    };
    init();
    return {
      keywords,
      canUpdateUser,
      canUpdateState,
      permissionConfig,
      // dispatchDepConfig,
      params,
      tableTotal,
      tableData,
      tableLoading,
      handleSearch,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
      renderColumns,
      handleAddSuccess,
      handleEnableChange,

      supplierDialogConfig,
      handleAddOrgUser,
      handleEditOrgUser,

      sortFiltersTags,
      handleRemoveFilterItem,
      // handleReset,
      searchConfig,
      // handleCurrentSizeChange,
      // init,
    };
  },
});
