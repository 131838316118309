
import { defineComponent, PropType, ref } from 'vue';
import { ElForm, ElMessage } from 'element-plus';
import { validatefuns } from 'cx-utils';
import {
  IOrgUserOperateForm,
  SUP_DIALOG_OPERATE_ENUM,
  SUP_DIALOG_OPERATE_LIST,
  ISupplierOperateConfig,
} from './type';
import { ISupplierFindSupplierCollectionListItem } from '@/modules/setting-manage/supplier-manage/api/type';
import { postSupplierAdd, postSupplierUpdate } from '@/modules/setting-manage/supplier-manage/api';

export default defineComponent({
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
    },
    // 新增、编辑
    operateType: {
      type: String as PropType<SUP_DIALOG_OPERATE_ENUM>,
      default: SUP_DIALOG_OPERATE_ENUM.ADD,
    },
    data: {
      type: Object as PropType<ISupplierFindSupplierCollectionListItem | null>,
    },
  },
  setup(props: ISupplierOperateConfig, { emit }) {
    const formRef = ref<InstanceType<typeof ElForm> | null>(null);
    const form = ref<IOrgUserOperateForm>({
      supplierName: '',
      contacts: '',
      mobile: '',
    });
    const formRules = {
      supplierName: [{ required: true, message: '请输入供应商名称' }],
      contacts: [{ required: true, message: '请输入联系人' }],
      mobile: [
        {
          required: true,
          validator: (rule: any, value: string, callback: any) => {
            if (!validatefuns.isMobilephone(value)) {
              callback(new Error('联系电话格式错误'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
      ],
    };
    const handleClose = async () => {
      try {
        // 初始化表单
        await formRef.value!.resetFields();
        emit('update:visible', false);
      } catch (e) {
        console.log(e);
      }
    };
    const handleConfirm = async () => {
      try {
        await formRef.value?.validate();
        if (props.operateType === SUP_DIALOG_OPERATE_ENUM.ADD) {
          await postSupplierAdd({
            ...form.value,
          });
        } else {
          await postSupplierUpdate({
            id: props.data?.id || '',
            ...form.value,
          });
        }
        ElMessage.success('操作成功');
        handleClose();
        emit('success');
      } catch (e) {
        console.log(e);
      }
    };

    const handleOpen = async () => {
      if (props.operateType === SUP_DIALOG_OPERATE_ENUM.ADD) {
        form.value.supplierName = '';
        form.value.contacts = '';
        form.value.mobile = '';
      } else {
        form.value.supplierName = props.data!.supplierName;
        form.value.contacts = props.data!.contacts;
        form.value.mobile = props.data!.mobile;
      }
    };
    return {
      formRef,
      form,
      formRules,
      handleConfirm,
      handleOpen,
      handleClose,
      SUP_DIALOG_OPERATE_ENUM,
      SUP_DIALOG_OPERATE_LIST,
    };
  },
});
