import type{ ISearchConfigItem } from '../type.d';
import { computed, Ref } from 'vue';
import { ICustomParams } from '../type.d';
import {
  ENABLE_STATUS_LIST,
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/setting-manage/supplier-manage/constant';
import { ISupplierFindAllCreatorRes } from '@/modules/setting-manage/supplier-manage/api/type';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
  CREATOR_OPTIONS:Ref<ISupplierFindAllCreatorRes>,
) => {
  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.IS_ENABLE,
        render: () => {
          return (
          <virtual-select
            label="启用状态"
            v-model={params.value.isEnable}
            options={ENABLE_STATUS_LIST}
          />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.CREATOR_ID,
        render: () => {
          return (
            <virtual-select
              label="创建人"
              v-model={params.value.creatorId}
              options={CREATOR_OPTIONS.value}
              prop={{
                label: 'creatorName',
                value: 'creatorId',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.CREATED_TIME,
        render: () => {
          return (
            <div>
              <custom-date-picker
                label="创建时间"
                v-model={params.value.createdTime}
              >
              </custom-date-picker>
            </div>
          );
        },
      },
    ];
    return baseConfig;
  });
  return {
    searchConfig,
  };
};
